import { mapGetters } from 'vuex';
import { closeCurrentTag } from '@/utils/tags';
import { handleDatabaseRequestError } from '@/utils/error';
import { hasDeletePermissionForCode } from '@/utils/permission';
import { objectsAreEqual, deepClone, array_concat, asyncForEach, tryGetValue, getFirstFocusableInEl } from '@/utils';
import { entityToStore } from '@/utils/store';
import { getDefaultSummary } from '@/utils/form';
const debounce = require('lodash.debounce');

export default {
  props: {
    autoGeneratedId: {
      default: true,
      type: Boolean
    },
    definition: {
      default() {
        return {};
      },
      type: Object
    },
    disabled: {
      default: false,
      type: Boolean
    },
    id: {
      type: Number,
      default: 0
    },
    fetchById: {
      default: id => {
        throw new Error('fetchById is not provided as a prop');
      },
      type: Function
    },
    deleteById: {
      type: Function
    },
    hideDeleteBtn: {
      type: Boolean,
      default: false
    },
    disableDeleteBtn: {
      type: Boolean,
      default: false
    },
    preCreateFetch: {
      default: () => Promise.resolve(),
      type: Function
    },
    isEdit: {
      default: false,
      type: Boolean
    },
    itemType: {
      default: '',
      type: String
    },
    returnTo: {
      default: '',
      type: String
    },
    save: {
      type: Function
    },
    pageLoading: {
      type: Boolean,
      default: false
    },
    isEmbedded: {
      type: Boolean,
      default: false
    },
    forceValues: {
      type: Object,
      default: () => ({})
    },
    saveButtonPosition: {
      type: String,
      default: 'top'
    },
    preventDefault: {
      type: Boolean,
      default: false
    },
    getDependencies: {
      type: Function,
      default: () => []
    },
    promptRouteLeave: Function,
    injectFormValues: {
      type: Object, // When object is changed, the values of injectFormValues are copied into form, changing the fields' values
      default: () => ({})
    },
    getTagTitle: Function,
    getLayout: Function,
    getRouteOnSave: Function,
    labelPosition: {
      type: String,
      default: 'right'
    },
    workflow: {
      type: Function
    },
    generateSummary: {
      type: Function,
      default: getDefaultSummary
    }
  },
  data() {
    const fieldsDefinition = this.definition;
    const fieldNames = Object.keys(fieldsDefinition);
    this.defaultForm = Object.assign(
      {},
      fieldNames.reduce((form, name) => {
        form[name] = fieldsDefinition[name].defaultValue;
        return form;
      }, {})
    );

    const fields = {};
    fieldNames.reduce((target, name) => {
      const field = fieldsDefinition[name];
      if (field.type) {
        target[name] = field;
      }
      return target;
    }, fields);

    return {
      errorMessage: '',
      formItemStyle: {},
      errors: {},
      amountOfErrors: 0,
      formData: {},
      form: Object.assign({}, this.defaultForm),
      actionLoading: false,
      tagViewRoute: {},
      userListOptions: [],
      formLabelPosition: 'right',
      forceExit: false,
      preCloseHooks: {},
      preSaveHooks: {},
      postSaveHooks: {},
      finalizeSaveHooks: {},
      requestErrorHooks: {},
      valueCleaners: {},
      editorNumber: 0,
      initialFocusState: {},
      fetchError: null
    };
  },
  computed: {
    innerDisabled() {
      return this.disabled || !!this.fetchError;
    },
    definitionsObject() {
      return this.definition;
    },
    fields() {
      const fieldsDefinition = this.definition;
      const fieldNames = Object.keys(fieldsDefinition);

      const fields = {};
      fieldNames.reduce((target, name) => {
        const field = fieldsDefinition[name];
        if (field.type) {
          target[name] = field;
        }
        return target;
      }, fields);

      return fields;
    },
    columnsFields() {
      if (this.getLayout) {
        const layout = this.getLayout(this);
        const columnsProps = this.generateColumnsPropertiesFromPageLayout(layout);
        return columnsProps.columnFields;
      }
      return this.splitFieldsInHalfAsArray(this.fields);
    },
    columnsWidthPercentages() {
      if (this.getLayout) {
        const layout = this.getLayout(this);
        const columnsProps = this.generateColumnsPropertiesFromPageLayout(layout);
        return columnsProps.widths;
      }
      return [];
    },
    ...mapGetters(['editorOptions', 'validationErrorInputPrefix']),
    showSaveAndNext() {
      return this.showSave && this.editorOptions.btnSaveAndNext;
    },
    permissionCode() {
      return tryGetValue(this.$store.state, entityToStore(this.itemType), 'permissionName') || this.itemType;
    },
    hasDeletePermission() {
      return hasDeletePermissionForCode(this.permissionCode, this.$store.getters.permissions);
    },
    showSave() {
      return !!this.save && !this.innerDisabled;
    },
    showSaveAndClose() {
      return this.showSave;
    },
    showDelete() {
      return (
        !this.innerDisabled &&
        !this.hideDeleteBtn &&
        this.isEdit &&
        this.editorOptions.btnDelete &&
        this.hasDeletePermission
      );
    },
    fetchErrorCode() {
      return (this.fetchError && this.fetchError.response && this.fetchError.response.status) || null;
    },
    fetchErrorMessage() {
      return typeof this.fetchError === 'string' ? this.fetchError : null;
    },
    pageMessage() {
      if (this.fetchErrorMessage) {
        return this.fetchErrorMessage;
      }
      if (this.fetchErrorCode) {
        return this.$i18n.t('editor.entityFetchError');
      }
      if (this.innerDisabled) {
        return this.$i18n.t('permission.pageIsReadonly');
      }
      return '';
    },
    valuesQueueItem() {
      return this.$store.getters['editor/valuesQueueItem'](this.editorNumber);
    },
    initQueueItem() {
      return this.$store.getters['editor/initQueueItem'](this.editorNumber);
    },
    actionSaveYN() {
      return this.$store.getters['editor/actionSaveYN'];
    },
    showOk() {
      return !this.isEmbedded && this.editorOptions.btnOk;
    },
    btnOkLabel() {
      return this.workflow ? 'wizard.next' : 'common.ok';
    }
  },
  watch: {
    forceValues(val, oldVal) {
      if (!objectsAreEqual(val, oldVal)) {
        this.refreshFormValues();
      }
    },
    definition(definition, oldVal) {
      if (this.actionLoading) {
        // Don't reload definition when saving or deleting to
        // prevent store getters in prop definitions causing form reset behaviour
        return;
      }
      if (JSON.stringify(definition) !== JSON.stringify(oldVal)) {
        this.refreshFormValues();
      }
    },
    id(val) {
      if (this.isEdit) {
        const id = this.id || (this.$route.params && this.$route.params.id);
        this.fetchData(id);
      }
    },
    form: {
      deep: true,
      handler: function(val) {
        this.$store.dispatch('editor/updateForm', { form: val, editorNumber: this.editorNumber });
        const flatFields = this.getFlatFields();
        const changedKeys = Object.keys(val).filter(key => val[key] !== (this.formSnapshot && this.formSnapshot[key]));
        Object.keys(flatFields).forEach(name => {
          if (flatFields[name].valueOnFormChange) {
            const newVal = flatFields[name].valueOnFormChange(val, this.form[name], changedKeys, this.formSnapshot);
            const oldVal = this.form[name];
            let valueIsChanged = false;
            if (Array.isArray(newVal) && typeof oldVal === 'object') {
              valueIsChanged = this.formObjectsAreDifferent(newVal, Object.values(oldVal));
            } else {
              valueIsChanged = newVal !== oldVal;
            }
            if (valueIsChanged) {
              this.$set(this.form, name, newVal);
            }
          }
        });
        this.formSnapshot = Object.assign({}, val);

        this.$emit('form', val);
        this.debouncedValidate();
      }
    },
    formData: {
      deep: true,
      handler: function(val) {
        this.$store.dispatch('editor/updateInitial', { initial: val, editorNumber: this.editorNumber });
      }
    },
    injectFormValues(injectFormValues) {
      this.injectValues(injectFormValues);
    },
    valuesQueueItem(valuesQueueItem) {
      if (valuesQueueItem) {
        this.injectValues(valuesQueueItem);
        this.$store.commit('editor/CLEAR_VALUES_QUEUE_ITEM', this.editorNumber);
      }
    },
    initQueueItem(initQueueItem) {
      if (initQueueItem) {
        this.injectInitValues(initQueueItem);
        this.$store.commit('editor/CLEAR_INIT_QUEUE_ITEM', this.editorNumber);
      }
    },
    actionSaveYN(actionSaveYN) {
      if (actionSaveYN) {
        this.$store.commit('editor/CLEAR_FLAG_ACTION_SAVE');
        this.handleSave();
      }
    }
  },
  async created() {
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of the setTagsViewTitle function,
    // this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tagViewRoute = Object.assign({}, this.$route);

    this.editorNumber = await this.$store.dispatch('editor/register', { form: this.form });
    window.addEventListener('beforeunload', this.beforeUnload, false);
    if (this.isEdit) {
      const id = this.id || (this.$route.params && this.$route.params.id);
      this.fetchData(id).then(data => {
        const dependencies = this.getDependencies(data);
        dependencies.forEach(dep => {
          const params = dep.params || {};
          if (dep.inactiveID) {
            params.query = {
              includeID: dep.inactiveID
            };
          }
          this.$store.dispatch(dep.entity + '/getItems', params);
        });
      });
    } else {
      this.fetchDependencies();
      await this.handleCrucialFetch(this.preCreateFetch());
      this.handleInitialFocus('fetched');
    }

    this.refreshFormValues();
    this.$emit('form', this.form);
    this.formSnapshot = Object.assign({}, this.form);

    this.$store.dispatch('request/disableErrorCodePrefix', this.validationErrorInputPrefix);

    this.$emit('created', { editorNumber: this.editorNumber });
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('resize', this.handleResize);
    this.$store.dispatch('request/enableErrorCodePrefix', this.validationErrorInputPrefix);
    this.$store.dispatch('editor/unregister', this.editorNumber);
  },
  mounted() {
    this.debouncedValidate = debounce(this.validateForm, 200);
    this.debouncedHandleSave = debounce(this.handleSave, 4000);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.handleInitialFocus('mounted');
  },
  methods: {
    handleWorkflow() {
      this.workflow ? this.workflow() : this.defaultWorkflow();
    },
    defaultWorkflow() {
      this.disabled ? this.close() : this.saveAndClose();
    },
    doSave() {
      if (this.save) {
        return this.save(this.form);
      }
      return new Promise(resolve => resolve());
    },
    replaceForm(newForm) {
      if (JSON.stringify(this.form) !== JSON.stringify(newForm)) {
        this.form = Object.assign({}, newForm);
      }
    },
    injectValues(values) {
      const newValues = Object.keys(values).reduce((newValues, key) => {
        newValues[key] = values[key];
        this.$set(this.form, key, values[key]);
        return newValues;
      }, {});
      this.replaceForm(Object.assign({}, this.form, newValues));
    },
    injectInitValues(values) {
      this.formData = Object.assign({}, this.formData, values);
    },
    handleInitialFocus(action) {
      this.initialFocusState[action] = true;
      const { mounted, fetched } = this.initialFocusState;
      if (mounted && fetched) {
        this.setFocus();
      }
    },
    validateForm() {
      const form = this.$refs.form;
      if (form) {
        form.validate().catch(_ => {});
      }
    },
    getRules(fields) {
      return Object.keys(fields).reduce((rules, key) => {
        const staticRules = fields[key].rules;
        const dynamicRules = fields[key].dynamicRules && fields[key].dynamicRules(this.form);
        rules[key] = array_concat(staticRules || [], dynamicRules || []);
        return rules;
      }, {});
    },
    getWidthFromPercentage(percentage, columns) {
      if (percentage === undefined) {
        return 24 / (this.isEmbedded ? 1 : columns.length);
      }
      return Math.floor(0.01 * percentage * 24);
    },
    generateColumnsPropertiesFromPageLayout(layout, tabIndex) {
      const { named, sections } = layout;
      const columns = [];
      const columnWidths = [];
      const flatFields = this.getFlatFields();
      if (named) {
        sections.forEach(({ fields, width }) => {
          const columnFields = fields.reduce((column, fieldName) => {
            column[fieldName] = flatFields[fieldName];
            return column;
          }, {});
          columns.push(columnFields);
          columnWidths.push(width);
        });
      } else {
        const fieldsOnThisPage = tabIndex === undefined ? flatFields : this.fields[tabIndex];
        const fieldNamesStack = Object.keys(fieldsOnThisPage);
        sections.forEach(({ capacity, width }) => {
          if (!fieldNamesStack.length) {
            return;
          }
          let columnFields = {};
          if (capacity === undefined) {
            columnFields = fieldNamesStack.reduce((column, fieldName) => {
              column[fieldName] = flatFields[fieldName];
              return column;
            }, {});
          } else {
            for (let i = 0; i < capacity && fieldNamesStack.length; i++) {
              const fieldName = fieldNamesStack.shift();
              columnFields[fieldName] = flatFields[fieldName];
            }
          }
          columns.push(columnFields);
          columnWidths.push(width);
        });
      }
      return { columnFields: columns, widths: columnWidths };
    },
    getLabel(spec, name) {
      const specs = this.getProps(spec);
      if (Array.isArray(specs.caption)) {
        return this.$i18n.t(`${specs.caption[0]}`, specs.caption[1]);
      }
      return (specs.caption && this.$i18n.t(`${specs.caption}`)) || this.$i18n.t(`common.${name}`);
    },
    getVisibleProp(spec) {
      const staticProp = this.getStaticProps(spec).visible;
      if (staticProp === false) {
        return staticProp;
      }
      return staticProp || this.getDynamicProps(spec).visible;
    },
    getDisabledProp(spec) {
      return this.innerDisabled || !!this.getStaticProps(spec).disabled || this.getDynamicProps(spec).disabled;
    },
    getProps(spec) {
      return { ...this.getStaticProps(spec), ...this.getDynamicProps(spec) };
    },
    getStaticProps(spec) {
      return spec.props || {};
    },
    getDynamicProps(spec) {
      return (spec.dynamicProps && spec.dynamicProps(this.form)) || {};
    },
    setFocus() {
      this.$nextTick(() => {
        const firstComponent = this.getFirstFocusableInputField();
        if (firstComponent && typeof firstComponent.focus === 'function') {
          firstComponent.focus();
        }
      });
    },
    createRef(grandparent, parent, child) {
      let me = 'inputField' + grandparent.toString().padStart(4, '0');
      if (parent !== undefined) {
        me += parent.toString().padStart(4, '0');
      }
      if (child !== undefined) {
        me += child.toString().padStart(4, '0');
      }
      return me;
    },
    getFirstFocusableInputField() {
      const inputFieldRefKeys = Object.keys(this.$refs)
        .filter(key => key.startsWith('inputField'))
        .sort();
      return this.getFirstFocusableFromRefs(inputFieldRefKeys);
    },
    getFirstFocusableFromRefs(refs) {
      return this.getFirstFocusableComponentFromRefs(refs) || this.getFirstFocusableElFromRefs(refs);
    },
    getFirstFocusableComponentFromRefs(refs) {
      for (let i = 0; i < refs.length; i++) {
        const key = refs[i];
        const component = this.$refs[key][0];
        if (
          component &&
          !component.disabled &&
          !component.$attrs.disabled &&
          component.$attrs.visible !== false &&
          component.focus
        ) {
          return component;
        }
      }
      return undefined;
    },
    getFirstFocusableElFromRefs(refs) {
      for (let i = 0; i < refs.length; i++) {
        const key = refs[i];
        const component = this.$refs[key][0];
        const focusable = getFirstFocusableInEl(component.$el);
        if (focusable) {
          return focusable;
        }
      }
      return undefined;
    },
    handleCrucialFetch(promise) {
      this.fetchError = null;
      const pageVersionBeforeFetch = this.$store.getters['pageVersion'];
      return promise.catch(error => {
        const pageVersion = this.$store.getters['pageVersion'];
        if (pageVersion !== pageVersionBeforeFetch) {
          return;
        }
        this.fetchError = error;
      });
    },
    getFlatFields() {
      let flatFields = this.fields;

      if (Array.isArray(flatFields)) {
        flatFields = flatFields.reduce((acc, tabFields) => {
          acc = { ...acc, ...tabFields };
          return acc;
        }, {});
      }
      return flatFields;
    },
    formHasChanged() {
      return this.$store.getters['editor/hasValuesChanged']({
        editorNumber: this.editorNumber,
        fieldNames: Object.keys(this.getFlatFields()),
        cleaners: this.valueCleaners
      });
    },
    clearInputErrorFor(name, additionalFields) {
      delete this.errors[name.toLowerCase()];
      if (Array.isArray(additionalFields)) {
        additionalFields.forEach(field => {
          delete this.errors[field.toLowerCase()];
        });
      }
    },
    getInputErrors(errors, name, spec, form) {
      const { showErrorsFromFields, warnings } = spec;
      const warningMessages = this.getInputWarnings(name, warnings, form);
      const fieldError = errors[name.toLowerCase()];
      const errorsFromOtherFields = this.getErrorsFromFields(errors, showErrorsFromFields);
      return array_concat(fieldError, errorsFromOtherFields, warningMessages);
    },
    getInputWarnings(name, warningsSpec, form) {
      const warningMessages = [];

      const warningsSpecAsArray = typeof warningsSpec === 'function' ? warningsSpec(form) : warningsSpec;
      if (Array.isArray(warningsSpecAsArray)) {
        const warningCallback = error => {
          if (error) {
            warningMessages.push({ message: error.message, type: 'warning' });
          }
        };
        warningsSpecAsArray.forEach(warning => {
          warning.validator(null, form[name], warningCallback);
        });
      }
      return warningMessages;
    },
    getErrorsFromFields(errors, fields) {
      if (!Array.isArray(fields)) {
        return [];
      }
      return fields
        .map(field => errors[field.toLowerCase()])
        .filter(error => error)
        .reduce((flat, err) => [...flat, ...err], []);
    },
    doPromptRouteLeave() {
      if (this.forceRouteLeave) {
        return Promise.resolve();
      }
      if (this.promptRouteLeave) {
        return this.promptRouteLeave();
      }
      return this.$store.dispatch('notify/confirm', {
        title: this.$i18n.t('common.warning'),
        message: this.$i18n.t('common.leave_unsaved_warning'),
        confirmButtonText: this.$i18n.t('common.save'),
        cancelButtonText: this.$i18n.t('common.dontSave')
      });
    },
    handleRouteLeave(to, from, next) {
      if (this.formHasChanged() && !this.forceExit) {
        const saveErrorMsg = this.$i18n.t('error.save');
        next(false);
        this.doPromptRouteLeave()
          .then(async () => {
            try {
              const done = await this.persistForm();
              if (done) {
                next();
              }
            } catch (err) {
              this.$store.dispatch('toast', { message: saveErrorMsg });
              console.log(err);
            }
          })
          .catch(action => {
            if (action === 'cancel') {
              next();
            } else {
              next(false);
            }
          });
      } else {
        next();
      }
    },
    beforeUnload(e) {
      if (this.formHasChanged()) {
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    },
    fetchDependencies(dep) {
      const dependencies = this.getDependencies();
      dependencies.forEach(dep => {
        const params = dep.params || {};
        this.$store.dispatch(dep.entity + '/getItems', params);
      });
    },
    handleResize() {
      const { width } = this.$el.getBoundingClientRect();
      if (width <= 767) {
        this.formLabelPosition = 'top';
      } else {
        this.formLabelPosition = this.labelPosition;
      }
    },
    splitFieldsInHalfAsArray(fields) {
      const keys = Object.keys(fields);
      if (keys.length < 4) {
        const columns = [];
        columns.push(fields);
        return columns;
      }
      let columns = [];
      columns = keys.reduce((acc, key, index) => {
        const columnIndex = index < keys.length / 2 ? 0 : 1;
        if (!acc[columnIndex]) {
          acc[columnIndex] = {};
        }
        acc[columnIndex][key] = fields[key];
        return acc;
      }, columns);
      return columns;
    },
    handleInit(name, init) {
      this.formData[name] = init;
    },
    async handleChangeFocus({ name }) {
      const keys = Object.keys(this.$refs);
      const newFocusRefKey = keys.find(key => tryGetValue(this.$refs, key, 0, 'name') === name);
      newFocusRefKey && this.$refs[newFocusRefKey][0].focus();
      if (newFocusRefKey === undefined) {
        console.log(`could not find ref with name: ${name}`);
      }
    },
    clearForm() {
      this.formData = {};
      this.refreshFormValues();
      this.$refs.form.clearValidate();
    },
    refreshFormValues() {
      // this.form cannot be Computed because it is passed as v-model for input components
      const fieldsDefinition = this.definitionsObject;
      const fieldNames = Object.keys(fieldsDefinition);
      const defaultForm = Object.assign(
        {},
        fieldNames.reduce((form, name) => {
          form[name] = fieldsDefinition[name].defaultValue;
          return form;
        }, {})
      );
      this.replaceForm(Object.assign({}, defaultForm, this.formData, this.forceValues));
      this.formData = deepClone(this.form);
    },
    fetchData(id) {
      this.$store.dispatch('request/disableErrorHTTPCode', 403);
      this.$store.dispatch('request/disableErrorHTTPCode', 404);
      return this.handleCrucialFetch(
        this.fetchById(id)
          .then(data => {
            this.formData = Object.assign(this.formData, data);
            this.$set(this.formData, 'id', data.id);
            this.refreshFormValues();
            this.refreshTagTitle(data);
            this.$refs.form.clearValidate();
            this.handleInitialFocus('fetched');
            this.$emit('fetched');
            return data;
          })
          .finally(_ => {
            this.$store.dispatch('request/enableErrorHTTPCode', 403);
            this.$store.dispatch('request/enableErrorHTTPCode', 404);
          })
      );
    },
    refreshTagTitle(item) {
      if (this.isEdit) {
        const newTag = this.getTagTitle ? { title: this.getTagTitle(item) } : { name: item.name || item.code || '' };
        this.setTagsViewTitle(newTag);
      }
    },
    mapToPath(statusCode) {
      switch (statusCode) {
        case 403:
          return '/403';
        case 404:
          return '/404';
        default:
          return null;
      }
    },
    registerHook(type, name, hook) {
      if (hook) {
        if (!this[type][name]) {
          this[type][name] = [];
        }
        this[type][name].push(hook);
      } else {
        delete this[type][name];
      }
    },
    registerRequestErrorHook(name, hookDefinition) {
      this.registerHook('requestErrorHooks', hookDefinition.code, hookDefinition.action);
    },
    registerPreSaveHook(name, hook) {
      this.registerHook('preSaveHooks', name, hook);
    },
    registerPostSaveHook(name, hook) {
      this.registerHook('postSaveHooks', name, hook);
    },
    registerFinalizeSaveHook(name, hook) {
      this.registerHook('finalizeSaveHooks', name, hook);
    },
    registerPreCloseHook(name, hook) {
      this.registerHook('preCloseHooks', name, hook);
    },
    registerCleaner(name, getterFn) {
      this.valueCleaners[name] = getterFn;
    },
    async performPreCloseHooks() {
      const componentHooks = Object.keys(this.preCloseHooks).map(name => this.preCloseHooks[name]);

      await asyncForEach(componentHooks, async hooks => {
        await asyncForEach(hooks, async hook => {
          await hook(this.form);
        });
      });
    },
    async performPreSaveHooks() {
      const componentHooks = Object.keys(this.preSaveHooks).map(name => this.preSaveHooks[name]);

      await asyncForEach(componentHooks, async hooks => {
        await asyncForEach(hooks, async hook => {
          await hook(this.form);
        });
      });
    },
    async performPostSaveHooks() {
      const componentHooks = Object.keys(this.postSaveHooks).map(name => this.postSaveHooks[name]);

      await asyncForEach(componentHooks, async hooks => {
        await asyncForEach(hooks, async hook => {
          await hook(this.form);
        });
      });
    },
    async performFinalizeSaveHooks() {
      const componentHooks = Object.keys(this.finalizeSaveHooks).map(name => this.finalizeSaveHooks[name]);

      await asyncForEach(componentHooks, async hooks => {
        await asyncForEach(hooks, async hook => {
          await hook(this.form);
        });
      });
    },
    setTagsViewTitle({ name, title }) {
      if (this.isEmbedded) {
        return;
      }
      if (title === undefined) {
        title = this.$i18n.t('route.edit' + this.itemType) + ' ' + (name || '');
      }
      this.tagViewRoute = Object.assign({}, this.tagViewRoute, {
        title: `${title}`
      });
      this.$store.dispatch('updateVisitedView', this.tagViewRoute);
    },
    async persistForm() {
      this.errorMessage = '';
      this.replaceForm(Object.assign(this.form, this.forceValues));

      let result;
      try {
        this.actionLoading = true;
        await this.validate();
        await this.performPreSaveHooks();
        const response = await this.doPersistForm();
        await this.performPostSaveHooks();
        return (result = response || true);
      } catch (err) {
        return (result = false);
      } finally {
        this.actionLoading = false;
        try {
          await this.performFinalizeSaveHooks(result);
        } catch (err) {
          console.warn('Error occured during finalizing save action: ', err);
        }
      }
    },
    async validate() {
      try {
        await this.$refs.form.validate();
      } catch (err) {
        // if there are more errors than previous validation, display this notification
        if (Object.keys(err).length > this.amountOfErrors) {
          this.$store.dispatch('notify/formValidationFailed');
        }

        this.amountOfErrors = Object.keys(err).length;
        throw err;
      }

      this.amountOfErrors = 0;
    },
    async doPersistForm() {
      if (!this.isEdit && this.autoGeneratedId) {
        // make sure no id is present
        delete this.form.id;
      }
      this.$emit('save');
      this.errors = Object.assign({});
      try {
        const _form = Object.assign({}, this.form);
        const response = await this.doSave(this.form);
        // This fixes bug that form magically resets after save()
        this.replaceForm(_form);
        if (!this.preventDefault && (!response || !(typeof response === 'string'))) {
          if (this.isEdit) {
            this.$store.dispatch('notify/dataUpdated');
          } else {
            this.$store.dispatch('notify/dataCreated');
          }
        }
        this.formData = Object.assign(this.formData, this.form, {});
        this.refreshTagTitle(this.form);
        this.$emit('saved', this.form);
        this.$emit('persisted', response);
        this.$root.$emit('editor-saved', this.editorNumber, this.form);

        return response;
      } catch (error) {
        this.errors = handleDatabaseRequestError(this, error, this.requestErrorHooks, this.preventDefault);
        throw error;
      }
    },
    async handleOnChange(spec) {
      await this.handleSaveDebounced();
      await this.saveAndReloadDataOnChange(spec);
    },
    async handleSaveDebounced() {
      return this.debouncedHandleSave();
    },
    // Perhaps for future use, decide whether isEdit is needed (add another true/false check)
    async saveAndReloadDataOnChange(spec) {
      // useful for very specific checkboxes (e.g. archive)
      const specs = this.getProps(spec);

      if (specs.saveAndReloadDataOnChange && this.isEdit) {
        this.debouncedHandleSave.cancel();
        await this.handleSave();

        const id = this.id || (this.$route.params && this.$route.params.id);
        await this.fetchData(id);
      }
    },
    async handleSave() {
      const item = await this.persistForm();
      if (item && !this.isEdit && !this.isEmbedded && !this.preventDefault) {
        closeCurrentTag(
          this,
          this.getRouteOnSave
            ? this.getRouteOnSave(item)
            : {
                name: 'Edit' + this.itemType,
                params: { id: item.id }
              },
          false
        );
      }
      return item;
    },
    async handleDelete(askForConfirmation = true) {
      if (!this.isEdit) {
        throw new Error('Delete not allowed');
      }
      const id = this.id || (this.$route.params && this.$route.params.id);
      try {
        if (askForConfirmation) {
          const summary = this.getSummary();
          await this.$store.dispatch('notify/deleteConfirm', summary);
        }
        this.actionLoading = true;
        this.deleteById ? await this.deleteById(id) : await this.defaultDeleteById(id);
        if (!this.preventDefault) {
          this.forceExit = true;
          closeCurrentTag(this, false, { name: this.returnTo });
        }
      } catch (_) {
        // do nothing
      }
      this.actionLoading = false;
    },
    defaultDeleteById(id) {
      const entityStoreName = entityToStore(this.itemType);
      return this.$store.dispatch(entityStoreName + '/deleteItem', { id }).then(() => {
        this.$store.dispatch('notify/deleteCompleted');
      });
    },
    async saveAndClose() {
      const done = await this.persistForm();
      if (done && !this.preventDefault) {
        closeCurrentTag(this, false, { name: this.returnTo });
      }
    },
    async saveAndNext() {
      const done = await this.persistForm();
      if (done && !this.preventDefault) {
        const { params } = this.$route;
        const name = 'Create' + this.itemType;

        if (this.isEdit) {
          this.$router.push({ name, params });
        } else {
          let href = this.$router.resolve({ name, params }).href;
          href = href.replace(/^#/g, '');
          this.$router.push({ path: '/redirect' + href });
        }
      }
    },
    getSummary() {
      const entityName = this.$i18n.t('entity.' + this.itemType);
      return `${entityName} ${this.generateSummary(this.form)}`;
    },
    formArraysAreDifferent(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return true;
      }
      const _arr1 = arr1.sort();
      const _arr2 = arr2.sort();
      const isDifferent = _arr1.some((val, index) => {
        if (_arr1[index] && typeof _arr2[index] === typeof {}) {
          return this.formObjectsAreDifferent(_arr1[index], _arr2[index]);
        }
        return _arr1[index] !== _arr2[index];
      });
      return isDifferent;
    },
    formObjectsAreDifferent(obj1, obj2) {
      const isDifferent = Object.keys(obj1).some(key => {
        if (obj1[key] && typeof obj1[key] === typeof {}) {
          if (Array.isArray(obj1[key])) {
            return this.formArraysAreDifferent(obj1[key], obj2[key]);
          } else if (obj2 && obj2[key] !== undefined) {
            return this.formObjectsAreDifferent(obj1[key], obj2[key]);
          }
          return false;
        }
        // Assume null to be same as empty string
        const _val1 = obj1[key] === null ? '' : obj1[key];
        const _val2 = obj2 === null || obj2[key] === null ? '' : obj2[key];
        // Debug code
        // if (_val1 !== _val2) {
        //   console.log('different: ', { key, val1: obj1[key], val2: obj2[key] });
        // }
        return _val1 !== _val2;
      });
      return isDifferent;
    },
    async close() {
      try {
        if (this.formHasChanged()) {
          await this.$store.dispatch('notify/closeUnsavedConfirm');
        }
        if (!this.preventDefault) {
          await this.performPreCloseHooks();
          this.forceExit = true;
          closeCurrentTag(this, false, { name: this.returnTo });
        }
      } catch (err) {
        // do nothing and stay on page
      }
    }
  }
};
