<template>
  <div class="sticky-container" :style="{ height: height + 'px', zIndex: zIndex }">
    <div
      :class="className"
      :style="{ top: stickyTop + 'px', zIndex: zIndex, position: position, width: width, height: height + 'px' }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sticky',
  props: {
    className: {
      default: '',
      type: String
    },
    stickyTop: {
      default: 0,
      type: Number
    },
    zIndex: {
      default: 2001,
      type: Number
    }
  },
  data() {
    return {
      active: false,
      height: undefined,
      isSticky: false,
      position: '',
      width: undefined
    };
  },
  activated() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    // this.height = this.$el.getBoundingClientRect().height;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (this.isSticky) {
        this.width = this.$el.getBoundingClientRect().width + 'px';
      }
    },
    handleReset() {
      if (!this.active) {
        return;
      }
      this.reset();
    },
    handleScroll() {
      const height = this.$el.getBoundingClientRect().height;
      this.height = height || 'auto';
      const width = this.$el.getBoundingClientRect().width;
      this.width = width || 'auto';
      const offsetTop = this.$el.getBoundingClientRect().top;
      if (offsetTop < this.stickyTop) {
        this.sticky();
        return;
      }
      this.handleReset();
    },
    reset() {
      this.position = '';
      this.width = 'auto';
      this.active = false;
      this.isSticky = false;
    },
    sticky() {
      if (this.active) {
        return;
      }
      this.position = 'fixed';
      this.active = true;
      this.width = this.width + 'px';
      this.isSticky = true;
    }
  }
};
</script>
